<template>
  <v-card class="pa-2 pt-4 mx-auto ml-xl-0" max-width="832">
    <v-img :src="require('@/assets/images/svg/keshLogo.svg')" max-width="100" class="mx-auto"></v-img>
    <v-card-title>{{ $t('title') }}</v-card-title>
    <v-card-subtitle>{{ $t('forgotPasswordMessage') }}</v-card-subtitle>
    <v-row align="center" justify="center" class="mx-2 my-4">
      <router-link :to="{ name: 'Login' }">{{ $t('backToLogin') }}</router-link>
    </v-row>
  </v-card>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'ForgotPasswordInfo',
})
</script>

<i18n>
{
  "en" : {
    "title": "Password reset requested successfully!",
    "forgotPasswordMessage": "We have sent your reset password link. Please check your email and use the link reset your password.",
    "backToLogin": "Back to login"
  },
  "al" : {
    "title": "Kerkesa per rivendosje fjalekalimi u krye!",
    "forgotPasswordMessage": "Ju kemi derguar linkun per ndryshimin e fjalekalimit. Ju lutem kontrolloni email-in tuaj dhe perdorni ate link per te rivendosur fjalekalimin.",
    "backToLogin": "Kthehu tek nderfaqja per tu kycur"
  }
}
</i18n>
