


























import Vue from 'vue'
import { required, email } from 'vuelidate/lib/validators'
import ForgotPasswordInfo from '@/components/Auth/ForgotPasswordInfo.vue'
import SubmitButton from '@/components/buttons/SubmitButton.vue'

export default Vue.extend({
  name: 'ForgotPassword',
  components: { SubmitButton, ForgotPasswordInfo },
  data() {
    return {
      email: '',
      showForm: true,
    }
  },
  validations: {
    email: {
      required,
      email,
    },
  },
  computed: {
    emailErrors() {
      const errors: string[] = []

      if (!this.$v.email.$dirty) {
        return errors
      }

      !this.$v.email.email && errors.push(this.$t('validation.emailField', { field: 'Email' }) as string)
      !this.$v.email.required && errors.push(this.$t('validation.requiredField', { field: 'Email' }) as string)

      return errors
    },
  },
  methods: {
    submit(): void {
      this.$v.$touch()

      if (this.$v.$anyError) {
        return
      }
      // TODO: Implement API call
      console.log('send reset password link', this.email)
      this.showForm = false
    },
  },
})
